import gql from 'graphql-tag';

export const ReactionsPlaceholderQuery = gql`
	query ReactionsPlaceholderQuery($contentId: ID!) {
		content(id: $contentId) {
			nodes {
				id
				contentReactionsSummary {
					reactionsSummaryForEmoji {
						id
					}
				}
			}
		}
	}
`;
