export const isClickEventToOpenNewTab = <E extends React.SyntheticEvent | undefined>(
	event: E,
	options?: {
		shouldCheckIframe?: boolean;
	},
): boolean | undefined => {
	return (
		event?.isDefaultPrevented() &&
		((Boolean(options?.shouldCheckIframe) && isIframe()) ||
			isCtrlOrCommandClick(event) ||
			isMiddleClick(event))
	);
};

const isIframe = () => window.parent !== parent;

const isMiddleClick = (event: Parameters<typeof isClickEventToOpenNewTab>[0]): boolean => {
	const nativeEvent = event?.nativeEvent as MouseEvent;

	return nativeEvent.button === 1;
};

const isCtrlOrCommandClick = (event: Parameters<typeof isClickEventToOpenNewTab>[0]): boolean => {
	const nativeEvent = event?.nativeEvent as MouseEvent;

	return nativeEvent.ctrlKey || nativeEvent.metaKey;
};
