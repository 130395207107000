import type { MouseEvent, PropsWithChildren } from 'react';
import React, { forwardRef, useContext } from 'react';
import { Link as ReactRouterLink } from 'react-router-dom';

import type { RoutesContextType } from './RoutesContext';
import { RoutesContext } from './RoutesContext';
import { getSpaTransitionHref } from './GlobalAnchorHandler';

export type LinkProps = PropsWithChildren<{
	className?: string;
	onClick?: (e: MouseEvent) => void;
	onContextMenu?: (e: MouseEvent) => void;
	href?: string;
	name?: string;
	params?: {
		[key: string]: any;
	};
	to?: string | null;
	target?: string;
	testId?: string;
	'aria-label'?: string;
	style?: object;
}>;

export const Link = forwardRef<HTMLAnchorElement & ReactRouterLink, LinkProps>((props, ref) => {
	const { target = undefined, name, href = null, to = null, params = {} } = props;
	const routesContext = useContext(RoutesContext);
	const { matchSupportedRoute, toUrl } = routesContext;

	const customPath = href || to;

	if (!customPath && !name) {
		if (process.env.NODE_ENV !== 'production') {
			// eslint-disable-next-line
			console.error('Error: Unable to build a valid link.');
		}
		return <WrappedAnchor {...props} ref={ref} path="" />;
	}

	const hrefParams = customPath && { href: customPath };
	const path = toUrl(name, { ...params, ...hrefParams });
	const match = matchSupportedRoute(path);

	return match && target !== '_blank' ? (
		<WrappedReactRouterLink {...props} ref={ref} match={match} />
	) : (
		<WrappedAnchor {...props} ref={ref} path={path} />
	);
});

const WrappedAnchor = forwardRef<HTMLAnchorElement & ReactRouterLink, LinkProps & { path: string }>(
	({ path, ...parentProps }, ref) => {
		const { children, className, target = undefined, testId, style } = parentProps;

		const routesContext = useContext(RoutesContext);
		const { onPageReload } = routesContext;

		const onAnchorLinkClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
			// @ts-ignore this should probably be `e.currentTarget`
			onPageReload(e.target.getAttribute('href'), false);

			if (parentProps.onClick) {
				parentProps.onClick(e);
			}
		};

		const onAnchorContextMenuClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
			parentProps.onContextMenu?.(e);
		};

		return (
			<a
				href={path}
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
				className={className}
				onClick={onAnchorLinkClick}
				onContextMenu={onAnchorContextMenuClick}
				target={target}
				rel={target === '_blank' ? 'noreferrer' : undefined}
				data-testid={testId}
				ref={ref}
				aria-label={parentProps['aria-label']}
				// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
				style={style}
			>
				{children}
			</a>
		);
	},
);

const WrappedReactRouterLink = forwardRef<
	HTMLAnchorElement & ReactRouterLink,
	LinkProps & {
		match: NonNullable<ReturnType<RoutesContextType['matchSupportedRoute']>>;
	}
>(({ match, ...parentProps }, ref) => {
	const routesContext = useContext(RoutesContext);

	const { children, className, style } = parentProps;
	const { preloadRoute } = routesContext;

	const onMouseDownCapture = async () => preloadRoute(match);
	/**
	 * Define the `onClick` handler for `react-router-dom`
	 * [Link](https://github.com/ReactTraining/react-router/blob/346153e38365e6a88657ce2e4e5acada290fc430/packages/react-router-dom/modules/Link.js)
	 * to make sure the SPA transition will go through RouteManager's push
	 * method. Under the hood `RouteManager#push` still calls `history.push`, but
	 * in addition it can reach out specified route policy if any. If not
	 * eligible for SPA transition, then it will continue delegate to
	 * react-router-dom Link to handle the click.
	 */
	const onClick = (event: MouseEvent<HTMLAnchorElement>) => {
		if (parentProps.onClick) {
			parentProps.onClick(event);
		}
		const spaTransitionHref = getSpaTransitionHref(event, routesContext);

		if (!event.defaultPrevented && spaTransitionHref) {
			event.preventDefault();
			routesContext.push(spaTransitionHref);
		}
	};

	const onContextMenu = (e: React.MouseEvent<HTMLAnchorElement>) => {
		parentProps.onContextMenu?.(e);
	};

	return (
		<ReactRouterLink
			to={`${match.pathname}${match.search}${match.hash}`}
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
			className={className}
			onClick={onClick}
			onContextMenu={onContextMenu}
			onMouseDownCapture={onMouseDownCapture}
			innerRef={ref}
			aria-label={parentProps['aria-label']}
			// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
			style={style}
		>
			{children}
		</ReactRouterLink>
	);
});
