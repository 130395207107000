import { useMemo, useCallback } from 'react';

import type {
	LinkEventClickHandler,
	SmartCardEventClickHandler,
	MentionEventHandler,
	EventHandlers,
} from '@atlaskit/editor-common/ui';

import { isEmbeddedConfluence_DO_NOT_USE } from '@atlassian/embedded-confluence/isEmbeddedConfluence';

import { useRouteActions } from '@confluence/route-manager/entry-points/RouteState';
import { getAtlassianPeopleProfileUrlAsync } from '@confluence/profile';
import { isClickEventToOpenNewTab } from '@confluence/linking-platform';

type ClickEvent =
	| Parameters<SmartCardEventClickHandler>[0]
	| Parameters<LinkEventClickHandler>[0]
	| Parameters<MentionEventHandler>[2];

export const useEventHandlers = (): EventHandlers | undefined => {
	const { push } = useRouteActions();

	const handleClick = useCallback(
		(event: ClickEvent, url: string) => {
			if (!event?.isDefaultPrevented()) {
				event?.preventDefault();
			}

			if (isClickEventToOpenNewTab(event)) {
				window.open(url, '_blank');
			} else {
				push(url);
			}
		},
		[push],
	);

	return useMemo(
		() =>
			isEmbeddedConfluence_DO_NOT_USE()
				? {
						mention: {
							onClick: async (userId, _, event) => {
								event?.preventDefault();
								event?.stopPropagation();

								// For Confirmed with PM, we apply the same approach as "mobile-web" to open `/people` link directly.
								const url = await getAtlassianPeopleProfileUrlAsync(userId);

								handleClick(event, url);
							},
						},
						link: {
							onClick(e, url) {
								handleClick(e, url as string);
							},
						},
						smartCard: {
							onClick(e, url) {
								handleClick(e, url as string);
							},
						},
					}
				: undefined,
		[handleClick],
	);
};
